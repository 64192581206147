import React from 'react';
import { motion } from 'framer-motion';
import Aboutbg from '../assets/aboutbg.jfif'; // Ensure the path is correct
import Logo from '../assets/logo.png';

const LoadingScreen = () => {
  return (
    <div className="fixed inset-0 bg-gray-900 flex items-center justify-center overflow-hidden">
      {/* Background Animation */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        <motion.div
          className="absolute w-full h-full"
          animate={{ rotate: [0, 360] }}
          transition={{ repeat: Infinity, duration: 30, ease: "linear" }}
        >
          <div className="absolute w-64 h-64 rounded-full bg-orange-500 opacity-20"></div>
          <div className="absolute w-48 h-48 rounded-full bg-orange-500 opacity-20" style={{ animation: 'spin-reverse 30s linear infinite' }}></div>
          <motion.img
            src={Aboutbg}
            alt="Background"
            className="absolute w-24 h-24 opacity-30"
            style={{ bottom: '20%', right: '10%' }}
            animate={{ rotate: [0, 360] }}
            transition={{ repeat: Infinity, duration: 20, ease: "linear" }}
          />
        </motion.div>
      </div>
      {/* Centered Logo and Loading Text */}
      <div className="relative z-10 flex flex-col items-center justify-center text-white">
        <motion.img
          src={Logo}
          alt="Logo"
          className="w-52 h-32 md:w-48 md:h-48 lg:w-full lg:h-full"
          animate={{ scale: [1, 1.1, 1] }}
          transition={{ repeat: Infinity, duration: 2 }}
        />
        <div className="mt-4 text-xl md:text-2xl lg:text-3xl font-bold animate-pulse">Loading...</div>
      </div>
    </div>
  );
};

export default LoadingScreen;
