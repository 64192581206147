import React, { useState, useEffect, useRef } from 'react';
import { FaSmile, FaTrophy, FaUsers, FaCheckCircle } from 'react-icons/fa';
import Aboutbg from "../assets/aboutbg.jfif"; // Ensure you import the background image

const Counter = ({ start, end, isVisible }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    if (!isVisible) return;

    let increment = end > start ? 1 : -1;
    const duration = 1000;
    const stepTime = Math.abs(Math.floor(duration / (end - start)));

    const timer = setInterval(() => {
      setCount((prev) => {
        if (prev === end) {
          clearInterval(timer);
          return prev;
        }
        return prev + increment;
      });
    }, stepTime);

    return () => clearInterval(timer);
  }, [isVisible, start, end]);

  return <span>{count}+</span>;
};

const ExcellenceSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const ref = sectionRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="relative flex flex-col lg:flex-row bg-gray-900 p-6 md:p-8 lg:p-12 xl:p-16 space-y-8 lg:space-y-0 lg:space-x-8 xl:py-32 overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0 z-0">
        <div className="rotating-bg w-full h-full absolute">
          <div className="rotating-circle absolute bg-orange-500 w-64 h-64 rounded-full animate-spin-slow opacity-20"></div>
          <div className="rotating-circle-2 absolute bg-orange-500 w-48 h-48 rounded-full animate-spin-reverse opacity-20"></div>
          <img src={Aboutbg} alt="Background" className="rotating-img absolute w-16 h-16 opacity-40 animate-spin-reverse" style={{ bottom: '30%', right: '15%' }} />
        </div>
      </div>

      {/* Left Side */}
      <div className="relative w-full lg:w-1/2 px-4 lg:px-8 xl:px-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
          {/* First Box */}
          <div className="relative rounded-lg flex flex-col justify-end items-center text-center p-4 md:p-6 h-40 md:h-48">
            <FaSmile className="text-orange-500 text-2xl md:text-3xl mb-2" />
            <div className="text-orange-500 text-xl md:text-2xl font-bold">
              <Counter start={0} end={235} isVisible={isVisible} />
            </div>
            <div className="text-gray-100 text-sm md:text-base">Satisfied Clients</div>
          </div>
          {/* Second Box - Slightly Lower */}
          <div className="relative rounded-lg flex flex-col justify-end items-center text-center p-4 md:p-6 h-40 md:h-48 mt-4 md:mt-8">
            <FaTrophy className="text-orange-500 text-2xl md:text-3xl mb-2" />
            <div className="text-orange-500 text-xl md:text-2xl font-bold">
              <Counter start={0} end={15} isVisible={isVisible} />
            </div>
            <div className="text-gray-100 text-sm md:text-base">Awards Winning</div>
          </div>
          {/* Third Box */}
          <div className="relative rounded-lg flex flex-col justify-end items-center text-center p-4 md:p-6 h-40 md:h-48">
            <FaUsers className="text-orange-500 text-2xl md:text-3xl mb-2" />
            <div className="text-orange-500 text-xl md:text-2xl font-bold">
              <Counter start={0} end={50} isVisible={isVisible} />
            </div>
            <div className="text-gray-100 text-sm md:text-base">Team Members</div>
          </div>
          {/* Fourth Box - Slightly Lower */}
          <div className="relative rounded-lg flex flex-col justify-end items-center text-center p-4 md:p-6 h-40 md:h-48 mt-4 md:mt-8">
            <FaCheckCircle className="text-orange-500 text-2xl md:text-3xl mb-2" />
            <div className="text-orange-500 text-xl md:text-2xl font-bold">
              <Counter start={0} end={354} isVisible={isVisible} />
            </div>
            <div className="text-gray-100 text-sm md:text-base">Successful Projects</div>
          </div>
        </div>
      </div>

      {/* Right Side */}
      <div className="relative w-full lg:w-1/2">
        <div className="text-xl mb-4 md:text-xl lg:text-xl xl:text-xl font-bold text-gray-500">
          Excellence
        </div>
        <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold text-gray-100">
          <span className="text-orange-500">The Least We</span> Deliver!
        </h2>
        <p className="mt-4 md:mt-6 text-gray-100 text-sm md:text-base lg:text-lg xl:text-xl">
          We build high-performing, digitally transformative, and feature-packed digital products. 
          Our commitment is unwavering; we deliver nothing short of exceptional quality.
        </p>
        <ul className="mt-4 md:mt-6 space-y-4">
          <li className="flex items-start text-sm md:text-base lg:text-lg">
            <div className="text-orange-500 mr-2 text-xl">✓</div>
            <div>
              <strong className="font-semibold text-gray-200">Vast Talent Pool</strong>
              <p className='text-gray-300'> Our skilled developers seamlessly blend technical expertise with innovation, ensuring your project needs are met with expertise.</p>
            </div>
          </li>
          <li className="flex items-start text-sm md:text-base lg:text-lg">
            <div className="text-orange-500 mr-2 text-xl">✓</div>
            <div>
              <strong className="font-semibold text-gray-200">Consulting & Advisory</strong> 
              <p className='text-gray-300'> Empowering your decisions and strategies, we provide expert guidance and insights to drive your business success forward.</p>
            </div>
          </li>
          <li className="flex items-start text-sm md:text-base lg:text-lg">
            <div className="text-orange-500 mr-2 text-xl">✓</div>
            <div>
              <strong className="font-semibold text-gray-200">On-Stop Solution</strong>
              <p className='text-gray-300'>Our full-service approach ensures a smooth journey from start to finish, streamlining all project aspects for maximum efficiency and satisfaction.</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ExcellenceSection;
