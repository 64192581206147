import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/logo.png';

const servicesData = [
  { to: '/mobileappdevelopment', name: 'Mobile app development' },
  { to: '/webdevelopment', name: 'Web development' },
  { to: '/webdesign', name: 'Web designing' },
  { to: '/graphicdesigning', name: 'Graphic designing' },
  { to: '/uiuxdesign', name: 'UI/UX design' },
  { to: '/softwaredevelopment', name: 'Software development' },
  { to: '/digitalmarketing', name: 'Digital Marketing' },
  { to: '/professionalphotography', name: 'Professional Photography' },
];

const Navbar = ({ text, homeSectionRef, transparentOnHome }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const location = useLocation(); // Get the current route

  const navbarRef = useRef(null);
  const servicesDropdownRef = useRef(null);

  const [touchStartX, setTouchStartX] = useState(null);

  const toggleNavbar = () => setIsOpen(prev => !prev);
  const toggleServicesDropdown = () => setServicesDropdownOpen(prev => !prev);

  // Automatically close the mobile menu on link click
  const handleCloseMenu = () => {
    setIsOpen(false);
    setServicesDropdownOpen(false); // Ensure dropdown is also closed
  };

  useEffect(() => {
    if (transparentOnHome) {
      const handleScroll = () => {
        if (homeSectionRef.current) {
          const homeSectionBottom = homeSectionRef.current.getBoundingClientRect().bottom;
          setIsScrolled(homeSectionBottom < 0);
        }
      };

      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check

      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [homeSectionRef, transparentOnHome]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (servicesDropdownRef.current && !servicesDropdownRef.current.contains(event.target) && !navbarRef.current.contains(event.target)) {
        setServicesDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    // Add or remove the class to body element based on the mobile menu state
    document.body.classList.toggle('no-scroll', isOpen);
  }, [isOpen]);

  // Handle touch events for swipe gestures
  useEffect(() => {
    const handleTouchStart = (e) => {
      setTouchStartX(e.touches[0].clientX);
    };

    const handleTouchEnd = (e) => {
      const touchEndX = e.changedTouches[0].clientX;
      if (touchStartX && touchStartX - touchEndX > 50) { // Swipe left
        if (isOpen) {
          handleCloseMenu();
        }
      }
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isOpen, touchStartX]);

  const navbarClass = transparentOnHome
    ? isScrolled
      ? 'bg-gradient-to-r from-black to-gray-800 text-white shadow-lg'
      : 'bg-transparent text-white shadow-none'
    : 'bg-gradient-to-r from-black to-gray-800 text-white shadow-lg';

  const linkClass = 'relative px-3 py-2 rounded-md text-lg font-medium transition duration-300 before:absolute before:bottom-0 before:left-0 before:w-0 before:h-1 before:bg-orange-500 before:transition-all before:content-[""] hover:before:w-full';
  const activeLinkClass = 'before:w-full text-orange-500 hover:text-orange-500';

  return (
    <nav ref={navbarRef} className={`transition-all duration-300 ease-in-out fixed top-0 w-full z-50 ${navbarClass}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="relative flex items-center justify-between h-16">
          {/* Mobile menu button */}
          <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
            <button
              onClick={toggleNavbar}
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-black focus:outline-none"
            >
              <svg
                className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
              <svg
                className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Company Name */}
          <div className="flex-shrink-0 ml-10 sm:ml-10">
            <Link to="/" className="text-3xl font-bold tracking-wide hover:text-orange-500">
              <img src={Logo} alt="Company Logo" className="h-36 w-auto" />
            </Link>
          </div>

          {/* Desktop menu */}
          <div className="hidden lg:flex lg:items-center lg:justify-end flex-1">
            <div className="flex space-x-8 items-center">
              <Link
                to="/"
                className={`${linkClass} ${location.pathname === '/' ? activeLinkClass : ''}`}
                onClick={handleCloseMenu} // Close menu on link click
              >
                Home
              </Link>
              <Link
                to="/about"
                className={`${linkClass} ${location.pathname === '/about' ? activeLinkClass : ''}`}
                onClick={handleCloseMenu} // Close menu on link click
              >
                About
              </Link>
              {/* <div className="relative">
                <button
                  onClick={toggleServicesDropdown}
                  className={`${linkClass} ${servicesDropdownOpen ? activeLinkClass : ''}`}
                >
                  Services
                </button>
                {servicesDropdownOpen && (
                  <div ref={servicesDropdownRef} className="absolute top-full mt-2 w-48 bg-gray-800 text-white rounded-md shadow-lg z-10">
                    {servicesData.map(service => (
                      <Link
                        key={service.to}
                        to={service.to}
                        className={`block px-4 py-2 text-sm hover:bg-orange-500 transition duration-300 ${location.pathname === service.to ? 'bg-orange-500 text-white' : ''}`}
                        onClick={handleCloseMenu} // Close menu on link click
                      >
                        {service.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div> */}
              <div className="relative">
  <button
    onClick={toggleServicesDropdown}
    className={`${linkClass} ${servicesDropdownOpen ? activeLinkClass : ''}`}
  >
    Services
  </button>
  {servicesDropdownOpen && (
    <div ref={servicesDropdownRef} className="absolute top-full mt-2 w-96 bg-gray-800 text-white rounded-md shadow-lg z-10 grid grid-cols-2 gap-4 p-4">
      {servicesData.slice(0, 4).map(service => (
        <Link
          key={service.to}
          to={service.to}
          className={`block px-4 py-2 text-sm hover:bg-orange-500 transition duration-300 ${location.pathname === service.to ? 'bg-orange-500 text-white' : ''}`}
          onClick={handleCloseMenu} // Close menu on link click
        >
          {service.name}
        </Link>
      ))}
      {servicesData.slice(4, 8).map(service => (
        <Link
          key={service.to}
          to={service.to}
          className={`block px-4 py-2 text-sm hover:bg-orange-500 transition duration-300 ${location.pathname === service.to ? 'bg-orange-500 text-white' : ''}`}
          onClick={handleCloseMenu} // Close menu on link click
        >
          {service.name}
        </Link>
      ))}
    </div>
  )}
</div>

              <Link
                to="/careers"
                className={`${linkClass} ${location.pathname === '/careers' ? activeLinkClass : ''}`}
                onClick={handleCloseMenu} // Close menu on link click
              >
                Careers
              </Link>
              <Link
                to="/contact"
                className="relative text-white px-8 py-3 rounded-3xl text-xl font-bold bg-orange-500 hover:bg-white hover:text-orange-500 transition duration-300"
                onClick={handleCloseMenu} // Close menu on link click
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`lg:hidden overflow-hidden transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} fixed top-0 left-0 w-full h-full bg-gray-800 z-50`}
      >
        <div className="flex items-center justify-between px-3 py-0 bg-gray-900">
          <Link to="/" className="text-2xl font-bold text-white">
            <img src={Logo} alt="Company Logo" className="h-24 w-auto" />
          </Link>
          <button
            onClick={handleCloseMenu}
            className="text-white"
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link
            to="/"
            className={`relative block px-3 py-2 rounded-md text-base font-medium text-white border border-orange-500 hover:bg-orange-500 hover:text-white transition duration-300 ${location.pathname === '/' ? 'bg-orange-500 text-white' : ''}`}
            onClick={handleCloseMenu} // Close menu on link click
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`relative block px-3 py-2 rounded-md text-base font-medium text-white border border-orange-500 hover:bg-orange-500 hover:text-white transition duration-300 ${location.pathname === '/about' ? 'bg-orange-500 text-white' : ''}`}
            onClick={handleCloseMenu} // Close menu on link click
          >
            About
          </Link>
          <div className="relative">
            <button
              onClick={toggleServicesDropdown}
              className={`relative block px-3 py-2 rounded-md text-base font-medium text-white border border-orange-500 hover:bg-orange-500 hover:text-white transition duration-300 w-full text-left ${servicesDropdownOpen ? 'bg-orange-500 text-white' : ''}`}
            >
              Services
            </button>
            {servicesDropdownOpen && (
              <div ref={servicesDropdownRef} className="mt-2 bg-gray-800 text-white rounded-md shadow-lg z-10 max-h-48 overflow-y-auto">
                {servicesData.map(service => (
                  <Link
                    key={service.to}
                    to={service.to}
                    className={`block px-4 py-2 text-sm hover:bg-orange-500 transition duration-300 ${location.pathname === service.to ? 'bg-orange-500 text-white' : ''}`}
                    onClick={handleCloseMenu} // Close menu on link click
                  >
                    {service.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
          <Link
            to="/careers"
            className={`relative block px-3 py-2 rounded-md text-base font-medium text-white border border-orange-500 hover:bg-orange-500 hover:text-white transition duration-300 ${location.pathname === '/careers' ? 'bg-orange-500 text-white' : ''}`}
            onClick={handleCloseMenu} // Close menu on link click
          >
            Careers
          </Link>
          <Link
            to="/contact"
            className={`relative block px-3 py-2 rounded-md text-base font-medium text-white border border-orange-500 hover:bg-orange-500 hover:text-white transition duration-300 ${location.pathname === '/contact' ? 'bg-orange-500 text-white' : ''}`}
            onClick={handleCloseMenu} // Close menu on link click
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
