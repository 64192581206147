import React from 'react';
import { FaRocket, FaMobileAlt, FaPaintBrush, FaPalette, FaArrowRight, FaCode, FaDesktop, FaBullhorn, FaCamera } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Aboutbg from "../assets/aboutbg.jfif";
// Updated services array with link property
const services = [
  {
    title: 'MOBILE APP DEVELOPMENT',
    icon: <FaMobileAlt size={80} />,
    description: 'Check our services',
    link: '/mobileappdevelopment',
  },
  {
    title: 'WEB DEVELOPMENT',
    icon: <FaCode size={80} />,
    description: 'Check our services',
    link: '/webdevelopment',
  },
  {
    title: 'WEB DESIGNING',
    icon: <FaPaintBrush size={80} />,
    description: 'Check our services',
    link: '/webdesign',
  },
  {
    title: 'GRAPHIC DESIGNING',
    icon: <FaPalette size={80} />,
    description: 'Check our services',
    link: '/graphicdesigning',
  },
  {
    title: 'UI/UX DESIGNING',
    icon: <FaDesktop size={80} />,
    description: 'Check our services',
    link: '/uiuxdesign',
  },
  {
    title: 'SOFTWARE DEVELOPMENT',
    icon: <FaRocket size={80} />,
    description: 'Check our services',
    link: '/softwaredevelopment',
  },
  {
    title: 'DIGITAL MARKETING',
    icon: <FaBullhorn size={80} />,
    description: 'Check our services',
    link: '/digitalmarketing',
  },
  {
    title: 'PROFESSIONAL PHOTOGRAPHY',
    icon: <FaCamera size={80} />,
    description: 'Check our services',
    link: '/professionalphotography',
  },
];

// Updated ServiceCard component with link prop
const ServiceCard = ({ title, icon, description, link }) => {
  return (
    <Link to={link} className="group relative bg-gray-300 shadow-md p-8 text-left overflow-hidden transition-all duration-300 h-full flex flex-col justify-between">
      {/* Circles */}
      <div className="circle-1 absolute top-0 left-0 w-16 h-16 md:w-24 md:h-24 bg-orange-500 rounded-full opacity-20 transform translate-x-4 -translate-y-4 md:translate-x-6 md:-translate-y-6 transition-colors duration-300 z-10"></div>
      <div className="circle-2 absolute top-0 right-0 w-24 h-24 md:w-36 md:h-36 bg-orange-500 rounded-full opacity-20 transform translate-x-8 -translate-y-6 md:translate-x-12 md:-translate-y-8 transition-colors duration-300 z-10"></div>
      
      {/* Content */}
      <div className="relative z-20 flex-grow flex flex-col items-start">
        {/* Icon container */}
        <div className="mb-6 md:mb-8 text-orange-500 group-hover:text-white transition-colors duration-300">
          {icon}
        </div>
        {/* Title aligned to the left */}
        <h3 className="text-base md:text-lg font-bold mb-3 md:mb-4 text-black group-hover:text-white transition-colors duration-300">{title}</h3>
        <p className="text-sm md:text-base text-gray-600 mb-6 md:mb-8 group-hover:text-white transition-colors duration-300">{description}</p>
        <div className="text-gray-700 font-semibold group-hover:text-white transition-colors duration-300 flex items-center">
          <FaArrowRight size={30} className="transition-transform group-hover:translate-x-1 group-hover:text-white" />
        </div>
      </div>
    </Link>
  );
};

// ServicesGrid component
const ServicesGrid = () => {
  return (
    <div className="relative py-12 lg:px-36 xl:px-36 md:px-36 px-11 bg-gray-900 overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0 z-0">
        <div className="rotating-bg w-full h-full absolute">
          <div className="rotating-circle absolute bg-orange-500 w-72 h-72 rounded-full animate-spin-slow opacity-20"></div>
          <div className="rotating-circle-2 absolute bg-orange-500 w-48 h-48 rounded-full animate-spin-reverse opacity-20"></div>
          {/* Add small rotating images */}
          {/* <img src={Aboutbg} alt="Background" className="rotating-img absolute w-20 h-20 opacity-40 animate-spin-slow" style={{ top: '20%', left: '10%' }} /> */}
          <img src={Aboutbg} alt="Background" className="rotating-img absolute w-16 h-16 opacity-40 animate-spin-reverse" style={{ bottom: '30%', right: '15%' }} />
        </div>
      </div>

      {/* Heading and Paragraph */}
      <div className="relative z-10 text-center mb-12">
        <h2 className="text-5xl text-gray-200 font-bold mb-24">Our Services</h2>
      </div>
      {/* Grid */}
      <div className="relative z-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 md:gap-8">
        {services.map((service, index) => (
          <div key={index} className="relative h-[400px] clip-card-shape overflow-hidden transition-transform duration-300 group">
            <ServiceCard
              title={service.title}
              icon={service.icon}
              description={service.description}
              link={service.link}
            />
            <div className="absolute inset-0 bg-orange-500 opacity-0 transform scale-y-0 group-hover:scale-y-100 group-hover:opacity-100 origin-bottom transition-all duration-500"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesGrid;