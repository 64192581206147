import React, { useRef } from 'react';
import Navbar from '../components/Navbar';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer'
function ContactPage() {
  const homeRef = useRef(null);
  return (
    <>
      <Navbar text="Applotech" homeSectionRef={homeRef} transparentOnHome={false} />
      <ContactUs />
      <Footer/>
    </>
  );
}

export default ContactPage;
