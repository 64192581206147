import React from 'react';
import { AiFillFacebook, AiFillTwitterCircle, AiFillLinkedin, AiFillInstagram, AiOutlineMail, AiOutlinePhone, AiOutlineSearch } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {/* Company Info */}
        <div>
          <Link to="#" className="text-3xl font-bold tracking-wide">
            <img src={Logo} alt="Applotech Logo" className='h-32 w-auto -ml-5 -mt-10' />
          </Link>
          <p className="mb-4 mt-4 text-sm">
            Improving lives through technology
          </p>
          <p className="text-sm">
            Applotech is a leading software development company offering innovative web, mobile, blockchain, and AI solutions. Our team specializes in crafting custom software designed to meet your unique business needs. We also provide expert staff augmentation to enhance your in-house capabilities, ensuring the successful execution of complex IT projects.
          </p>
        </div>

        {/* Links */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <h3 className="font-bold mb-4 text-lg">Why Applotech</h3>
            <ul className="text-sm">
              <li><Link to="#" className="hover:underline">Client Reviews</Link></li>
              <li><Link to="#" className="hover:underline">Careers</Link></li>
              <li><Link to="#" className="hover:underline">Contact Us</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold mb-4 text-lg">Services</h3>
            <ul className="text-sm">
              <li><Link to="#" className="hover:underline">App Development</Link></li>
              <li><Link to="#" className="hover:underline">Software Development</Link></li>
              <li><Link to="#" className="hover:underline">Web Development</Link></li>
              <li><Link to="#" className="hover:underline">UI/UX Design</Link></li>
              <li><Link to="#" className="hover:underline">App Maintenance</Link></li>
              <li><Link to="#" className="hover:underline">API Integration</Link></li>
            </ul>
          </div>
        </div>

        {/* Contact Info */}
        <div>
          <h3 className="font-bold mb-4 text-lg">Get In Touch</h3>
          <p className="flex items-center mb-2 text-sm">
            <AiOutlineMail className="mr-2" />
            Info@applotech.com

          </p>
          <p className="flex items-center mb-2 text-sm">
            <AiOutlineMail className="mr-2" />
            Contact@applotech.com

          </p>
          <p className="flex items-center mb-2 text-sm">
            <AiOutlineMail className="mr-2" />
            Career@applotech.com

          </p>
          <div className="mt-4 text-sm">
            <h4 className="font-bold mb-2">Office</h4>
            <p className="flex items-center mb-2">
              <FaMapMarkerAlt className="mr-2" />
               Karachi, Pakistan
            </p>
            <p className="flex items-center">
              <AiOutlinePhone className="mr-2" />
              +92  346  2858293
            </p>
          </div>
          <div className="mt-4 flex flex-wrap gap-4">
            <Link to="https://www.facebook.com/applotech" target='_blank'>
              <AiFillFacebook className="text-2xl text-orange-500 hover:text-white" />
            </Link>
            <Link to="https://www.twitter.com/applotech" target='_blank'>
              <AiFillTwitterCircle className="text-2xl text-orange-500 hover:text-white" />
            </Link>
            <Link to="https://www.linkedin.com/company/applotech/" target='_blank'>
              <AiFillLinkedin className="text-2xl text-orange-500 hover:text-white" />
            </Link>
            <Link to="https://www.instagram.com/applotech" target='_blank'>
              <AiFillInstagram className="text-2xl text-orange-500 hover:text-white" />
            </Link>
          </div>
          <div className="mt-4 flex flex-wrap gap-2 items-center lg:-ml-96 md:-ml-72 md:mt-20 lg:mt-5">
            <div className="relative flex-1 max-w-xs">
              <AiOutlineSearch className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-400" />
              <input 
                type="text" 
                placeholder="Subscribe Us" 
                className="bg-gray-800 border border-gray-700 rounded-full pl-10 py-2 text-white placeholder-gray-400 focus:outline-none w-full"
              />
            </div>
            <button className="bg-orange-500 font-bold text-white rounded-full px-4 py-2 hover:bg-white hover:text-orange-500 focus:outline-none">
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-700 mt-8 pt-4">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="flex items-center mb-4 sm:mb-0 text-sm">
            <p>&copy; 2024 All Rights Reserved Applotech</p>
          </div>
          <div className="flex flex-wrap gap-4 text-sm">
            <Link to="#" className="hover:underline">Terms of Use</Link>
            <Link to="#" className="hover:underline">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
