import React, { useEffect, useState, useRef } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import sampleVideo1 from '../assets/video1.mp4';
import sampleVideo3 from '../assets/video3.mp4';
import sampleVideo7 from '../assets/video7.mp4';
// import { Link } from 'react-router-dom';

const videos = [sampleVideo1, sampleVideo7, sampleVideo3];

const content = [
  { name: 'WEB DEVELOPMENT', heading: 'WEB DEVELOPMENT', description: 'Our web development team builds responsive, user-friendly websites using modern frameworks like React, Angular, and Vue.js. We focus on creating digital experiences that engage users and elevate your brand’s online presence' },
  { name: 'APP DEVELOPMENT', heading: 'APP DEVELOPMENT', description: 'We specialize in crafting intuitive mobile applications for iOS and Android. Utilizing React Native, we develop apps that provide exceptional performance and functionality, keeping your business connected with customers on the go' },
  { name: 'SOFTWARE DEVELOPMENT', heading: 'SOFTWARE DEVELOPMENT', description: 'Our team develops custom software solutions, from CRM systems to ERP solutions. We leverage the latest technologies to create scalable, secure software that enhances business efficiency and innovation' }
];

const Hero = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [progressWidth, setProgressWidth] = useState(0);
  const controls = useAnimation();
  const videoRefs = useRef(videos.map(() => React.createRef()));

  useEffect(() => {
    const video = videoRefs.current[currentVideoIndex].current;
    if (video) {
      const updateProgress = () => {
        const progress = (video.currentTime / video.duration) * 100;
        setProgressWidth(progress);
      };

      video.addEventListener('timeupdate', updateProgress);
      return () => video.removeEventListener('timeupdate', updateProgress);
    }
  }, [currentVideoIndex]);

  useEffect(() => {
    controls.start({
      width: `${progressWidth}%`,
      transition: { duration: 0.5, ease: 'linear' }
    });
  }, [progressWidth, controls]);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currentVideoIndex + 1) % videos.length;
      setCurrentVideoIndex(nextIndex);
      setProgressWidth(0); // Reset progress width on video change
    }, 3000); // 3 seconds interval

    return () => clearInterval(interval);
  }, [currentVideoIndex]);

  const handlePointClick = (index) => {
    if (index === currentVideoIndex) return;

    setCurrentVideoIndex(index);
    setProgressWidth(0); // Reset progress width when manually changing videos
  };

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <div className="relative w-full h-full flex">
        <AnimatePresence mode="wait">
          {/* Video Transitions */}
          {videos.map((videoSrc, index) => (
            <motion.div
              key={index}
              className="absolute top-0 left-0 w-full h-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: index === currentVideoIndex ? 1 : 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <video
                ref={videoRefs.current[index]}
                src={videoSrc}
                autoPlay
                muted
                loop
                preload="auto"
                className="w-full h-full object-cover"
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      {/* Content and Progress Bars */}
      <div className="absolute inset-0 flex flex-col items-center justify-center p-4 bg-black bg-opacity-50 z-10 text-center space-y-6">
        <motion.div
          key={currentVideoIndex}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 1 }}
          className="text-white  px-4 flex flex-col items-center"
        >
          <h1 className="lg:text-6xl md:text-6xl text-4xl  font-bold mb-4 text-orange-500">{content[currentVideoIndex].heading}</h1>
          <p className="text-lg max-w-lg md:text-xl mb-6 text-justify">{content[currentVideoIndex].description}</p>

          {/* Clickable Names and Progress Bars */}
          <div className="flex flex-col items-center w-full">
            <div className="flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-4 space-y-4 md:space-y-0 mb-6">
              {content.map((item, index) => (
                <div key={index} className="flex flex-col items-center">
                  <button
                    className={`text-base md:text-lg cursor-pointer ${index === currentVideoIndex ? 'text-white font-bold' : 'text-gray-400'}`}
                    onClick={() => handlePointClick(index)}
                  >
                    {item.name}
                  </button>
                  {index === currentVideoIndex && (
                    <div className="w-24 bg-black bg-opacity-50 h-1 mt-1">
                      <motion.div
                        className="bg-orange-600 h-full"
                        style={{ width: `${progressWidth}%` }}
                        animate={controls}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Contact Button */}
          {/* <div className="flex justify-center mt-6">
            <Link
              to='/contact'
              className="relative text-white px-6 py-3 rounded-3xl text-lg font-bold bg-orange-500 hover:bg-white hover:text-orange-500 transition duration-300"
            >
              Contact Us
            </Link>
          </div> */}
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
