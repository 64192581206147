import React from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Aboutbg from "../assets/aboutbg.jfif";
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

const ServicesContent = ({ 
  mainHeading, 
  section1, 
  section2, 
  section3 
}) => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 600, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div className="relative">
      {/* Background Animation */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        <div className="rotating-bg w-full h-full absolute">
          {/* Increased number of rotating circles */}
          <div className="rotating-circle absolute bg-orange-500 w-28 h-28 rounded-full opacity-20 animate-spin-slow" style={{ top: '85%', left: '80%' }}></div>
          <div className="rotating-circle absolute bg-orange-500 w-60 h-60 rounded-full opacity-20 animate-spin-slow" style={{ bottom: '0%', right: '10%' }}></div>
          <div className="rotating-circle absolute bg-orange-500 w-48 h-48 rounded-full opacity-20 animate-spin-slow" style={{ top: '50%', left: '55%' }}></div>
          <div className="rotating-circle absolute bg-orange-500 w-56 h-56 rounded-full opacity-20 animate-spin-slow" style={{ top: '35%', left: '80%' }}></div>
          
          {/* Increased number of rotating image boxes */}
          <img src={Aboutbg} alt="Background" className="rotating-img absolute w-20 h-20 opacity-40 animate-spin-reverse" style={{ bottom: '10%', left: '20%' }} />
          <img src={Aboutbg} alt="Background" className="rotating-img absolute w-24 h-24 opacity-30 animate-spin-reverse" style={{ top: '25%', left: '40%' }} />
          <img src={Aboutbg} alt="Background" className="rotating-img absolute w-28 h-28 opacity-25 animate-spin-reverse" style={{ bottom: '50%', left: '20%' }} />
          <img src={Aboutbg} alt="Background" className="rotating-img absolute w-16 h-16 opacity-20 animate-spin-reverse" style={{ top: '70%', right: '10%' }} />
          <img src={Aboutbg} alt="Background" className="rotating-img absolute w-32 h-32 opacity-15 animate-spin-reverse" style={{ bottom: '30%', right: '90%' }} />
        </div>
      </div>

      {/* Top Section */}
      <div className="text-left p-8 sm:p-12 lg:p-16 bg-gray-900">
        <h1 className="lg:ml-14 md:ml-8 sm:ml-4 text-3xl sm:text-4xl md:text-5xl text-orange-500 font-extrabold">{mainHeading}</h1>
      </div>

      {/* Section 1 */}
      <div className="bg-gray-800 flex flex-col md:flex-row items-center justify-center p-8 sm:p-12 lg:p-24">
        <div className="md:w-1/2 p-4">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-100">{section1.heading}</h2>
          <p className='mt-4 sm:mt-8 text-base sm:text-lg md:text-xl text-gray-200'>{section1.paragraph}</p>
        </div>
        <div className="md:w-1/2 p-4">
          <img src={section1.image} alt="Section 1" className="w-full h-auto rounded-lg"/>
        </div>
      </div>

      {/* Section 2 - Our Expertise */}
      <div className="text-center py-8 sm:py-12 bg-gray-900">
        <h2 className="text-3xl sm:text-4xl md:text-5xl text-orange-500 font-bold">{section2.heading}</h2>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-center p-8 sm:p-12 lg:p-16 bg-gray-800">
        <div className="md:w-1/2 p-4">
          <img src={section2.imageLeft} alt="Section 2" className="w-full h-auto rounded-lg"/>
        </div>
        <div className="md:w-1/2 p-4 text-center md:text-left">
          <FontAwesomeIcon icon={section2.iconright} size="6x sm:8x" className="text-blue-600" />
          <h3 className="text-2xl sm:text-3xl md:text-4xl font-bold mt-4 mb-4 text-orange-500">{section2.subHeading}</h3>
          <p className='text-base sm:text-lg md:text-lg text-gray-200'>{section2.paragraph}</p>
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row items-center justify-center p-8 sm:p-12 lg:p-16 bg-gray-800">
        <div className="md:w-1/2 p-4 text-center md:text-right">
          <FontAwesomeIcon icon={section2.iconleft} size="6x sm:8x" className="text-green-600" />
          <h3 className="text-2xl sm:text-3xl md:text-4xl font-bold mt-4 mb-4 text-orange-500">{section2.subHeadingReverse}</h3>
          <p className='text-base sm:text-lg md:text-lg text-gray-200'>{section2.paragraphReverse}</p>
        </div>
        <div className="md:w-1/2 p-4">
          <img src={section2.imageRight} alt="Section 2 Reverse" className="w-full h-auto rounded-lg"/>
        </div>
      </div>

      {/* Section 3 - Technology Slider */}
      <div className="text-center py-8 sm:py-12 bg-gray-900">
        <h2 className="text-3xl sm:text-4xl md:text-5xl text-orange-500 font-bold">{section3.heading}</h2>
      </div>
      <div className="p-4 sm:p-8 lg:p-12 bg-gray-800">
        <Slider {...settings}>
          {section3.technologies.map((tech, index) => (
            <div key={index} className="text-center p-4">
              <img src={tech.image} alt={tech.name} className="w-12 h-12 sm:w-16 sm:h-16 mx-auto"/>
              <h4 className="mt-2 sm:mt-4 text-sm sm:text-base text-gray-200">{tech.name}</h4>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ServicesContent;
