import React, { useRef } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
// import Career from '../components/Career';
import Hero from "../components/Hero";
import AboutContent from "../components/AboutContent";
import { BiArrowToRight } from "react-icons/bi";
import CareerContent from "../components/CareeerContent";
import CareerForm from "../components/CareerForm";
import Career from "../assets/career.jpg";
import Aboutbg from "../assets/aboutbg.jfif"
function CareerPage() {
  const homeRef = useRef(null);
  const careerFormRef = useRef(null);
  const heading = "How We work";
  const textContent =
    "At Applotech, we are always on the lookout for talented individuals who are passionate about technology and innovation. Our team is a diverse mix of creative thinkers, problem solvers, and tech enthusiasts who work together to build cutting-edge solutions. We offer a dynamic and supportive work environment that encourages personal growth and professional development. Our projects span across various domains, including web development, mobile app development, blockchain, and artificial intelligence. As part of our team, you'll have the opportunity to work on challenging projects that make a real impact. We provide comprehensive training and mentorship programs to help you advance your skills and career. Whether you're a seasoned professional or just starting your career, Applotech is the place where you can achieve your full potential and be part of a team that shapes the future of digital innovation. Join us and embark on an exciting journey of growth and success.";
  const imageUrl = Career;

  return (
    <>
      <Navbar
        text="Applotech"
        homeSectionRef={homeRef}
        transparentOnHome={true}
      />
      <section id="home" ref={homeRef}>
        <Hero />
      </section>
      <AboutContent
        heading={heading}
        textContent={textContent}
        imageUrl={imageUrl}
        showSubheading={true}
        bgImage={Aboutbg}
        subheading={
          <span className="flex items-center">
            <BiArrowToRight className="mr-2" />
            career
          </span>
        }
      />
      <CareerContent />
      <CareerForm ref={careerFormRef} />
      <Footer />
    </>
  );
}

export default CareerPage;
