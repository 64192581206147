import React from "react";
import Ahmer from "../assets/ahmer.png"; 
import Saqib from "../assets/saqib.jpg";
import Anas from "../assets/anas.jpg";
import Muzammil from "../assets/muzammil.png";
import Ahsan from "../assets/ahsan.png";
import Awais from "../assets/awais.PNG";
import Aboutbg from "../assets/aboutbg.jfif";
import member0 from "../assets/member0.png"
import member1 from "../assets/member1.jpg";
import member2 from "../assets/member2.jpg"
import member3 from "../assets/member3.jpg"
import Kawaish from "../assets/kawaish2.jpg"
import Shahroz from "../assets/shahroz.jpg"

const teamMembers = [
  {
    name: "Sheikh Ahmer",
    role: "Chief Executive Officer",
    desc: "Visionary leader with extensive experience in strategic planning and business development, driving the company's growth.",
    image: Ahmer,
  },
  {
    name: "Saqib Raheem",
    role: "Web And Mobile App Developer",
    desc: "Expert in designing and developing innovative web and mobile applications, specializing in user-friendly interfaces and robust functionality.",
    image: Saqib,
  },
  {
    name: "Ahsan Mushtaq",
    role: "Full Stack Developer",
    desc: "Skilled in both front-end and back-end development, delivering comprehensive solutions that ensure seamless application performance.",
    image: Ahsan,
  },
  {
    name: "Muhammad Zubair",
    role: "Mobile App Developer",
    desc: "Proficient in creating engaging mobile applications tailored to user needs, focusing on performance and scalability.",
    image: member3,
  },
  {
    name: "Shahroz Aslam",
    role: "Senior Graphic Designer & E-Commerce Product Photographer",
    desc: "Highly skilled in visual design and product photography, delivering creative and compelling graphics along with high-quality e-commerce imagery that enhances brand presence and drives sales.",
    image: Shahroz,
  },
  {
    name: "S Muzammil Javed",
    role: "Full Stack Developer",
    desc: "Versatile developer with expertise in full-stack technologies, committed to delivering high-quality, efficient, and scalable web solutions.",
    image: Muzammil,
  },
  
  {
    name: "Muhammad Noman",
    role: "Graphic Designer",
    desc: "Creative professional dedicated to crafting visually appealing designs that effectively communicate brand messages.",
    image: member2,
  },
  {
    name: "Awais Khan",
    role: "CMS Developer",
    desc: "Specialist in WordPress development, providing customized content management solutions to enhance user engagement.",
    image: Awais,
  },
  {
    name: "Kawaish Sheikh",
    role: "Front End Developer",
    desc: "Expert in front-end technologies, dedicated to building responsive and interactive user interfaces that enhance user experience.",
    image: member1,
  },
  {
    name: "Kawish Siddiqui",
    role: "Sales Representative",
    desc: "Results-driven professional focused on building client relationships and delivering tailored solutions to drive sales growth.",
    image: Kawaish,
  },
  {
    name: "Ammad Noushad",
    role: "Sales Representative",
    desc: "Dynamic sales representative with a strong ability to engage clients and foster long-term business relationships.",
    image: member0,
  },
  {
    name: "Syed Anas",
    role: "UI/UX Designer",
    desc: "Passionate designer specializing in creating intuitive user interfaces and engaging user experiences that resonate with audiences.",
    image: Anas,
  },
  
  // Add more team members here...
];


const Team = () => {
  return (
    <div className="bg-gray-800 py-10 relative overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0 z-0">
        <div className="rotating-bg w-full h-full absolute">
          <div className="rotating-circle absolute bg-orange-500 w-44 h-44 rounded-full animate-spin-slow opacity-20"></div>
          <div className="rotating-circle-2 absolute bg-orange-500 w-48 h-48 rounded-full animate-spin-reverse opacity-20"></div>
          <img 
            src={Aboutbg}
            alt="" 
            className="rotating-img absolute w-16 h-16 opacity-40 animate-spin-reverse" 
            style={{ bottom: '10%', right: '15%' }} 
          />
        </div>
      </div>

      <h1 className="text-gray-200 text-3xl text-center mb-16 relative z-10">
        Meet the <span className="text-orange-500">team</span>
      </h1>
      <div className="flex flex-wrap justify-center gap-10 px-4 relative z-10">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className="relative group transition-all duration-300 transform hover:scale-105"
            style={{ width: '250px' }}
          >
            {/* Default State: Collapsed Card */}
            <div className="bg-transparent flex flex-col items-center transition-all duration-300 group-hover:bg-white group-hover:p-6 group-hover:shadow-lg group-hover:rounded-t-full rounded-lg overflow-hidden">
              {/* Profile Image */}
              <div className="relative w-28 h-28 rounded-full overflow-hidden mb-4">
                <img
                  src={member.image}
                  alt={member.name}
                  className="object-cover w-full h-full"
                />
              </div>

              {/* Position always visible */}
              <p className="text-gray-200 group-hover:text-gray-900 text-center">{member.role}</p>

              {/* Hover State: Expand to show Name, Social Icons, and Description */}
              <div className="flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 transform translate-y-0 group-hover:translate-y-4">
                {/* Name */}
                <h2 className="text-black text-lg font-bold mb-2 mt-2">
                  {member.name}
                </h2>

                {/* Social Icons */}
                {/* <div className="space-x-3 mb-4">
                  <a href={member.social.linkedin} target="_blank" rel="noreferrer">
                    <i className="fab fa-linkedin text-gray-700 text-xl"></i>
                  </a>
                  <a href={member.social.twitter} target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook text-gray-700 text-xl"></i>
                  </a>
                </div> */}

                {/* Description */}
                <p className="text-gray-500 text-sm text-center">
                  {member.desc}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
