import React from 'react';
import { FaReact, FaCss3Alt, FaAndroid, FaPython, FaWordpress, FaPhp, FaJava, FaNodeJs, FaVuejs, FaShopify, FaDrupal, FaAngular, FaDatabase } from 'react-icons/fa';
import { SiMongodb, SiXamarin, SiDjango, SiFirebase, SiAdobephotoshop } from 'react-icons/si';
import Aboutbg from "../assets/aboutbg.jfif";

const logos = [
    { icon: <FaReact />, color: '#61dafb', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move1' },
    { icon: <FaCss3Alt />, color: '#1572b6', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move2' },
    { icon: <FaAndroid />, color: '#a4c639', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move3' },
    { icon: <FaPython />, color: '#306998', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move4' },
    { icon: <FaWordpress />, color: '#21759b', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move5' },
    { icon: <FaPhp />, color: '#8993be', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move6' },
    { icon: <FaJava />, color: '#007396', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move7' },
    { icon: <FaNodeJs />, color: '#8cc84b', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move8' },
    { icon: <FaVuejs />, color: '#42b883', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move9' },
    { icon: <FaShopify />, color: '#7ab55c', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move10' },
    { icon: <SiMongodb />, color: '#47a248', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move11' },
    { icon: <FaDrupal />, color: '#003d77', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move12' },
    { icon: <SiXamarin />, color: '#0078d4', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move13' },
    { icon: <SiDjango />, color: 'green', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move14' },
    { icon: <FaAngular />, color: '#dd0031', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move13' },
    { icon: <FaDatabase />, color: '#00758F', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move14' },
    { icon: <SiFirebase />, color: '#FFCA28', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move13' },
    { icon: <SiAdobephotoshop />, color: '#31A8FF', size: 'text-4xl sm:text-5xl md:text-6xl', animation: 'move14' },
];

const LogoComponent = () => {
    return (
        <div className="relative bg-gray-900 p-12">
            {/* Animated Background */}
            <div className="absolute inset-0 z-0">
                <div className="rotating-bg w-full h-full absolute">
                    <div className="rotating-circle absolute bg-orange-500 w-72 h-72 rounded-full animate-spin-slow opacity-20"></div>
                    <div className="rotating-circle-2 absolute bg-orange-500 w-48 h-48 rounded-full animate-spin-reverse opacity-20"></div>
                    {/* Add small rotating images */}
                    <img src={Aboutbg} alt="Background" className="rotating-img absolute w-16 h-16 opacity-40 animate-spin-reverse" style={{ bottom: '30%', right: '15%' }} />
                </div>
            </div>
            <div className="flex items-center justify-center min-h-screen relative z-10">
                <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-x-32 gap-y-16">
                    {logos.map((logo, index) => (
                        <div
                            key={index}
                            className={`flex items-center justify-center w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 xl:w-32 xl:h-32 rounded-full border-4 border-gray-400 bg-gray-800 transition-transform transform-gpu ${logo.size} ${logo.animation} group hover:border-white`}
                            style={{ color: logo.color }}
                        >
                            <div className="group-hover:text-current filter grayscale group-hover:filter-none">
                                {logo.icon}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LogoComponent;
