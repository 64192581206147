import React, { useRef } from 'react';
import Navbar from '../components/Navbar';
// import Footer from '../components/Footer';
// import Homecontent from '../components/Homecontent';
import About from '../components/About';
import Services from '../components/Services';
// import Contact from '../components/Contact';
// import Team from '../components/Team';
import Career from '../components/Career';
import Hero from '../components/Hero';
import OurServices from '../components/OurServices';
import Footer from '../components/Footer';
import Review from '../components/Review';
import Slider from '../components/Slider';
import TeamSection from '../components/TeamSection';

function Home() {
  const homeRef = useRef(null);

  return (
    <>
      <Navbar text="Applotech" homeSectionRef={homeRef} transparentOnHome={true} />
      <section id="home" ref={homeRef}>
        {/* <Homecontent /> */}
        <Hero />
      </section>
      <OurServices />
      
      <section id="services">
        <Services />
      </section>
      <section id="about">
        <About />
      </section>
      {/* <section id="contact">
        <Contact />
      </section> */}
      <Review/>
      {/* <section id="team">
        <Team />
      </section> */}
      <section id="careers">
        <Career />
      </section>
      {/* <Team/> */}
      <Slider/>
      <TeamSection/>
      <Footer/>
    </>
  );
}

export default Home;
