import React, { useRef } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
// import AboutHero from "../components/AboutHero";
import AboutContent from "../components/AboutContent";
import AboutCompany from "../components/AboutCompany";
import Hero from "../components/Hero";
import Aboutimg from "../assets/aboutimg.jpg";
import Aboutbg from "../assets/aboutbg.jfif";
import Aboutimage from "../assets/aboutimage.jpg";
function AboutPage() {
  const homeRef = useRef(null);
  const heading = "Improving Lives";
  const subheading = "Through Technology";
  const textContent = `Founded in 2018, Applotech has swiftly established itself as a premier software house known for its innovative solutions and commitment to client success. With a rich collective experience spanning over 06 years, our team of 20 dedicated professionals is at the forefront of the digital innovation landscape.

Our mission is to provide top-notch software solutions that empower our clients to achieve their business goals efficiently and effectively. We pride ourselves on our ability to adapt to new technologies, ensuring that our services are always cutting-edge and tailored to meet the ever-evolving needs of the industry.

At Applotech, client satisfaction is our top priority. We are proud to have a 100% client satisfaction rate, a testament to our unwavering dedication to excellence and our client-centric approach. With over 30+ clients globally`;
  const imageUrl = Aboutimg;
  const textContent_2 =
    " Applotech is a leading software development agency with a team offull-stack developers, visionary designers, and tech enthusiasts forging unparalleled digital solutions for a tech-driven world.";
  return (
    <>
      <Navbar
        text="Applotech"
        homeSectionRef={homeRef}
        transparentOnHome={true}
      />
      <section id="home" ref={homeRef}>
        <Hero />
      </section>
      <AboutContent
        showSubheading={true}
        subheading="About Us"
        textContent={textContent_2}
        imageUrl={Aboutimage}
        bgColor="bg-gray-900"
        bgImage={Aboutbg}
      />

      <AboutContent
        showSubheading={true}
        heading={heading}
        subheading={subheading}
        textContent={textContent}
        imageUrl={imageUrl}
        bgColor="bg-gray-800"
        bgImage={Aboutbg}
      />
      <AboutCompany />
      <Footer />
    </>
  );
}

export default AboutPage;
