import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import Aboutpic from '../assets/aboutt.jpg';
import Aboutbg from "../assets/aboutbg.jfif";

const About = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Allow animations to trigger every time
    threshold: 0.1, // Trigger when 10% of the section is in view
  });

  return (
    <div className="relative min-h-screen bg-gray-800 py-20 flex flex-col justify-center items-center p-4 overflow-hidden text-white">
      {/* Background Animation */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        <div className="rotating-bg w-full h-full absolute">
          <div className="rotating-circle absolute bg-orange-500 w-72 h-72 rounded-full opacity-20 animate-spin-slow"></div>
          {/* <div className="rotating-circle-2 absolute bg-orange-500 w-48 h-48 rounded-full animate-spin-reverse"></div> */}
         
          <img src={Aboutbg} alt="Background" className="rotating-img absolute w-16 h-16 opacity-40 animate-spin-reverse" style={{ bottom: '20%', right: '50%' }} />
        </div>
      </div>

      {/* Main Content */}
      <motion.div
        ref={ref}
        className="relative flex flex-col justify-center items-center w-full max-w-screen-xl px-4 z-10"
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 1 }}
      >
        {/* Centered Heading */}
        <motion.div
          className="text-center mb-8"
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ delay: 0.5 }}
        >
          <div className="flex flex-col items-center mb-12">
            <h1 className="text-5xl font-extrabold text-orange-500 mb-4 leading-tight">
              About Us
            </h1>
          </div>
        </motion.div>

        <div className="flex flex-col-reverse md:flex-row items-center justify-center w-full max-w-screen-xl">
          {/* Left Side - Text */}
          <motion.div
            className="w-full md:w-1/2 flex flex-col items-center md:items-start text-center md:text-left p-4 md:p-8"
            initial={{ opacity: 0, x: -50 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-3xl font-bold mb-4">
              <span className="text-4xl text-orange-500">Improving Lives</span> Through Technology
            </h1>
            <p className="text-lg mb-4">
              We are a dedicated team specializing in web development and app
              development. Our expertise lies in crafting custom software
              solutions that drive business growth and enhance user engagement.
              We create dynamic websites and intuitive mobile applications
              tailored to your unique needs and goals. By leveraging the latest
              technologies and best practices, we ensure your digital presence
              is both effective and impactful. Our commitment is to provide
              high-quality, scalable solutions that meet the evolving demands of
              the digital landscape.
            </p>
          
            <Link
              to="/about"
              className="mt-8 relative text-white px-8 py-3 rounded-3xl text-xl font-bold bg-orange-500 hover:bg-white hover:text-orange-500 transition duration-300"
            >
              See More
            </Link>
          </motion.div>

          {/* Right Side - Image */}
          <motion.div
            className="w-full md:w-1/2 flex items-center justify-center p-4 md:p-8"
            initial={{ opacity: 0, x: 50 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }}
            transition={{ duration: 1 }}
          >
            <div className="relative w-full h-auto">
              <img
                src={Aboutpic}
                alt="About Us"
                className="w-full h-96 object-cover rounded-lg shadow-lg"
              />
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default About;
