import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useState, useEffect } from 'react';

import Home from './screens/Home';
import ContactPage from './screens/ContactPage';
import AboutPage from './screens/AboutPage';
import CareerPage from './screens/CareerPage';
import WebDevelopment from './screens/WebDevelopment';
import LoadingScreen from './components/LoadingScreen'; // Adjust path as needed

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ScrollToTop from './components/ScrollToTop.js';


function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay or check if content is loaded
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Adjust time as needed

    // Clear timer if the component is unmounted before the timeout
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Router>
           <ScrollToTop/>
          <div>
         
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/contact' element={<ContactPage />} />
              <Route exact path='/about' element={<AboutPage />} />
              <Route path='/:type' element={<WebDevelopment />} /> {/* Dynamic route */}
              <Route exact path='/careers' element={<CareerPage />} />
            </Routes>
          </div>
        </Router>
      )}
    </>
  );
}

export default App;
