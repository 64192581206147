import React from "react";

const AboutContent = ({
  heading,
  subheading,
  textContent,
  imageUrl,
  showSubheading = true,
  bgImage = null,
  bgColor = "bg-gray-800",
}) => {
  return (
    <div className={`relative flex flex-col items-start p-12 ${bgColor} overflow-hidden`}>
      {/* Animated Background */}
      <div className="absolute inset-0 z-0">
        <div className="rotating-bg w-full h-full absolute">
          <div className="rotating-circle absolute bg-orange-500 w-72 h-72 rounded-full animate-spin-slow opacity-20"></div>
          <div className="rotating-circle-2 absolute bg-orange-500 w-48 h-48 rounded-full animate-spin-reverse opacity-20"></div>
          {/* Add small rotating images if needed */}
          {bgImage && (
            <img src={bgImage} alt="Background" className="rotating-img absolute w-16 h-16 opacity-40 animate-spin-reverse" style={{ bottom: '30%', left: '45%' }} />
          )}
        </div>
      </div>

      {/* Content */}
      <div className="relative z-10 w-full h-auto mb-6">
        <h1 className="text-gray-100 ml-0 md:ml-12 text-3xl md:text-5xl font-bold">
          {heading} <br />
          {showSubheading && <span className="text-orange-500">{subheading}</span>}
        </h1>
      </div>

      <div className="relative z-10 flex flex-col md:flex-row gap-4 w-full">
        {/* Text Content */}
        <div className="flex items-center justify-center p-4 md:p-6 lg:p-12 flex-1">
          <p className="text-base md:text-xl text-gray-200">
            {textContent}
          </p>
        </div>

        {/* Image Content */}
        <div className="flex items-center justify-center p-4 md:p-6 lg:p-12 flex-1">
          <img
            src={imageUrl}
            alt="Description"
            className="w-full h-auto object-cover rounded-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
