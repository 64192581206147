import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { FaHandshake, FaProjectDiagram, FaChalkboardTeacher } from 'react-icons/fa';
import { AiOutlineTeam } from 'react-icons/ai';
import Image1 from '../assets/career1.jpg';
import Image2 from '../assets/career2.jpg';
import Image3 from '../assets/career3.jpg';
import CareerBg from '../assets/aboutbg.jfif';

const Career = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [counterKey, setCounterKey] = useState(0);

  const images = [Image1, Image2, Image3];

  const { ref: counterRef, inView: counterInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (counterInView) {
      setCounterKey(prevKey => prevKey + 1);
    }
  }, [counterInView]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="relative grid grid-cols-1 md:grid-cols-2 gap-8 p-4 pt-16 pb-16 sm:py-32 lg:p-8 xl:p-16 xl:pt-28 xl:pb-20 bg-gray-800 text-white">
      {/* Background Animation */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        <div className="relative w-full h-full overflow-hidden">
          <div className="rotating-bg absolute inset-0 w-full h-full">
            <div className="rotating-circle-2 absolute bg-orange-500 opacity-20 w-48 h-48 rounded-full animate-spin-reverse"></div>
            <img src={CareerBg} alt="Background" className="rotating-img absolute w-16 h-16 opacity-40 animate-spin-reverse" style={{ bottom: '30%', right: '15%' }} />
          </div>
        </div>
      </div>

      {/* Main Content - Image Section */}
      <div className="relative w-full h-64 sm:h-80 md:h-full overflow-hidden rounded-lg">
        <img
          src={images[currentImageIndex]}
          alt="Current"
          className="absolute inset-0 w-full h-full object-cover rounded-lg transition-opacity duration-1000 ease-in-out"
        />
        <div
          ref={counterRef}
          className={`absolute bottom-4 left-4 bg-orange-500 p-2 md:p-4 rounded-lg text-center transition-opacity duration-1000 ease-in-out ${counterInView ? 'opacity-100' : 'opacity-0'}`}
        >
          <h3 className="text-sm md:text-lg lg:text-xl xl:text-2xl font-bold text-white">
            <CountUp key={`happy-employees-${counterKey}`} start={0} end={20} duration={2} prefix="+" />
          </h3>
          <p className="text-xs md:text-sm lg:text-base text-white">Happy Employees</p>
        </div>
      </div>

      {/* Right Side - Text Section */}
      <div className="relative flex flex-col justify-between space-y-4">
        <div>
          <div className="flex items-center space-x-2">
            <AiOutlineTeam className="text-3xl text-orange-500" />
            <p className="text-lg md:text-xl lg:text-2xl font-semibold">Life At Applotech</p>
          </div>
          <p className="text-xl md:text-2xl lg:text-3xl font-bold">
            Unveiling the Future of Digital Innovation
            <br />
          </p>
          <p className="text-sm md:text-base lg:text-lg">
            Life at Applotech is like being part of a friendly and creative club. It's a place where work doesn't feel like work because we make it fun. Here's what you can expect:
          </p>
        </div>

        <ul className="list-disc list-inside ml-4 text-sm md:text-base lg:text-lg space-y-4">
          <li className="flex items-center space-x-2">
            <FaHandshake className="text-2xl text-orange-500" />
            <span>
              <strong>Making Friends:</strong>
              <br />
              You'll meet lots of new friends who are nice and supportive. We're like a big family.
            </span>
          </li>
          <li className="flex items-center space-x-2">
            <FaProjectDiagram className="text-2xl text-orange-500" />
            <span>
              <strong>Fun Projects:</strong>
              <br />
              We work on exciting projects for our clients. It's like solving cool puzzles and making cool things.
            </span>
          </li>
          <li className="flex items-center space-x-2">
            <FaChalkboardTeacher className="text-2xl text-orange-500" />
            <span>
              <strong>Learning and Growing:</strong>
              <br />
              We're all about learning new stuff. You learn cool things about digital tech.
            </span>
          </li>
        </ul>

        <Link
          to='/careers'
          className="text-white px-4 py-2 md:px-6 md:py-3 rounded-full text-lg md:text-xl lg:text-2xl font-bold bg-orange-500 hover:bg-white hover:text-orange-500 transition duration-300 text-center"
        >
          Explore Careers
        </Link>
      </div>
    </div>
  );
};

export default Career;
