import React from "react";
import { Link } from "react-router-dom";

const AboutCompany = () => {
  const stats = [
    { number: "2018", text: "Founded" },
    { number: "52+", text: "Projects Completed" },
    { number: "20+", text: "Talented Employees" },
    { number: "01", text: "Offices" },
    { number: "100%", text: "Client Satisfaction" },
  ];

  return (
    <div className="bg-gray-800 py-8">
      <div className="max-w-[80%] mx-auto border-t-2 border-gray-700">
        <div className="flex flex-col md:flex-row justify-around p-4">
          {stats.map((stat, index) => (
            <div key={index} className="text-center mx-2 mb-4 md:mx-4">
              <Link
                to={"/"}
                className="text-orange-500 text-4xl md:text-5xl font-bold block mb-2"
              >
                {stat.number}
              </Link>
              <span className="text-gray-100 text-sm md:text-base">
                {stat.text}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
