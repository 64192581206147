import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BiLock, BiLockOpen, BiChevronDown } from "react-icons/bi";

const CareerContent = () => {
  const [expandedJob, setExpandedJob] = useState(null);

  const jobs = [
    {
      title: "Frontend Developer",
      description: "You will be working on modern web technologies to create intuitive user interfaces.",
      responsibilities: [
        "Develop responsive web pages using React and Tailwind CSS.",
        "Collaborate with designers to implement UI/UX designs.",
        "Ensure cross-browser compatibility and performance optimization.",
        "Maintain and refactor existing codebase.",
        "Work closely with backend developers to integrate APIs.",
        "Participate in code reviews and team meetings."
      ],
      requirements: [
        "Proficiency in HTML, CSS, and JavaScript.",
        "Experience with React.js and modern frontend frameworks.",
        "Understanding of responsive design principles.",
        "Familiarity with version control (Git).",
        "Strong problem-solving skills.",
        "Ability to work in a fast-paced environment."
      ],
      preferredSkills: [
        "Experience with Tailwind CSS.",
        "Knowledge of TypeScript.",
        "Familiarity with Redux or other state management libraries."
      ],
      howToApply: "To apply, please fill out the application form provided below. We look forward to reviewing your application and potentially welcoming you to the Applotech team!"
    },
    {
      title: "Backend Developer",
      description: "You will be working on server-side logic and database management to power our web applications.",
      responsibilities: [
        "Develop and maintain backend services and APIs using Node.js, Express or python",
        "Collaborate with frontend developers to integrate services.",
        "Optimize database queries for performance.",
        "Implement security and data protection measures.",
        "Participate in system architecture discussions.",
        "Maintain and update existing backend services."
      ],
      requirements: [
        "Proficiency in Node.js or Python.",
        "Experience with databases (SQL/NoSQL).",
        "Understanding of RESTful API design.",
        "Familiarity with Docker and containerization.",
        "Strong debugging and problem-solving skills.",
        "Ability to work independently and in a team."
      ],
      preferredSkills: [
        "Experience with cloud platforms (AWS, Azure, etc.).",
        "Knowledge of microservices architecture.",
        "Familiarity with CI/CD pipelines."
      ],
      howToApply: "To apply, please fill out the application form provided below. We look forward to reviewing your application and potentially welcoming you to the Applotech team!"
      },
    {
      title: "Full Stack Developer",
      description: "As a Full Stack Developer, you will work on both frontend and backend development to build scalable web applications.",
      responsibilities: [
        "Develop and maintain web applications using React and Node.js.",
        "Integrate RESTful APIs and ensure seamless data flow between frontend and backend.",
        "Work closely with UI/UX designers to implement user-friendly interfaces.",
        "Optimize applications for maximum speed and scalability.",
        "Write clean, maintainable, and well-documented code.",
        "Collaborate with other developers and team members to solve complex problems."
      ],
      requirements: [
        "Proficiency in JavaScript, HTML, and CSS.",
        "Experience with React.js and Node.js.",
        "Strong understanding of RESTful API design.",
        "Familiarity with databases (SQL/NoSQL).",
        "Experience with version control systems (Git).",
        "Ability to work in an Agile environment."
      ],
      preferredSkills: [
        "Experience with TypeScript.",
        "Knowledge of Docker and containerization.",
        "Familiarity with CI/CD pipelines."
      ],
      howToApply: "To apply, please fill out the application form provided below. We look forward to reviewing your application and potentially welcoming you to the Applotech team!"
    },
    {
      title: "DevOps Engineer",
      description: "You will be responsible for managing and automating our infrastructure, ensuring continuous integration and delivery.",
      responsibilities: [
        "Manage and monitor cloud infrastructure (AWS, Azure, etc.).",
        "Automate deployment pipelines and server configurations.",
        "Ensure system reliability, availability, and performance.",
        "Implement security best practices across all environments.",
        "Collaborate with development teams to improve CI/CD processes.",
        "Troubleshoot and resolve infrastructure-related issues."
      ],
      requirements: [
        "Experience with cloud platforms (AWS, Azure, GCP).",
        "Proficiency in scripting languages (Bash, Python).",
        "Understanding of containerization (Docker, Kubernetes).",
        "Experience with CI/CD tools (Jenkins, GitLab CI).",
        "Strong knowledge of Linux/Unix systems.",
        "Ability to manage and monitor databases."
      ],
      preferredSkills: [
        "Experience with Terraform or Ansible.",
        "Knowledge of network security principles.",
        "Familiarity with monitoring tools (Prometheus, Grafana)."
      ],
      howToApply: "To apply, please fill out the application form provided below. We look forward to reviewing your application and potentially welcoming you to the Applotech team!" },
    {
      title: "Figma Designer (UI/UX Designer)",
      description: "Create intuitive and aesthetically pleasing designs that enhance user experience using Figma.",
      responsibilities: [
        "Design wireframes, prototypes, and high-fidelity visuals using Figma.",
        "Collaborate with product managers and developers to create user-centered designs.",
        "Conduct user research and usability testing.",
        "Ensure designs are consistent with brand guidelines.",
        "Iterate on feedback from stakeholders and users.",
        "Stay updated with the latest design trends and tools."
      ],
      requirements: [
        "Proficiency in Figma and other design tools (Sketch, Adobe XD).",
        "Strong understanding of UI/UX principles.",
        "Experience in creating responsive and adaptive designs.",
        "Ability to create design systems and component libraries.",
        "Excellent communication and collaboration skills.",
        "A strong portfolio showcasing your design work."
      ],
      preferredSkills: [
        "Experience with animation and prototyping tools.",
        "Knowledge of HTML/CSS for web design.",
        "Familiarity with design thinking methodologies."
      ],
      howToApply: "To apply, please fill out the application form provided below. We look forward to reviewing your application and potentially welcoming you to the Applotech team!"  },
    {
      title: "Project Manager",
      description: "Lead and manage projects from conception to delivery, ensuring they meet deadlines, budgets, and quality standards.",
      responsibilities: [
        "Define project scope, goals, and deliverables.",
        "Create and manage project timelines and budgets.",
        "Coordinate with cross-functional teams to ensure project success.",
        "Identify and manage project risks and issues.",
        "Communicate project status to stakeholders.",
        "Ensure projects are delivered on time and within scope."
      ],
      requirements: [
        "Proven experience as a project manager.",
        "Strong knowledge of project management methodologies (Agile, Scrum).",
        "Excellent organizational and multitasking skills.",
        "Ability to lead and motivate teams.",
        "Strong communication and interpersonal skills.",
        "Experience with project management tools (JIRA, Trello)."
      ],
      preferredSkills: [
        "PMP or Scrum Master certification.",
        "Experience in software development projects.",
        "Familiarity with risk management and quality assurance practices."
      ],
      howToApply: "To apply, please fill out the application form provided below. We look forward to reviewing your application and potentially welcoming you to the Applotech team!"  },
    {
      title: "Sales Executive",
      description: "Drive sales growth by identifying and closing new business opportunities, building strong client relationships.",
      responsibilities: [
        "Identify and develop new business opportunities.",
        "Build and maintain relationships with clients.",
        "Prepare and deliver sales presentations.",
        "Negotiate contracts and close deals.",
        "Achieve sales targets and KPIs.",
        "Collaborate with marketing and product teams."
      ],
      requirements: [
        "Proven experience in sales or business development.",
        "Strong negotiation and communication skills.",
        "Ability to build and maintain client relationships.",
        "Results-driven with a track record of meeting or exceeding targets.",
        "Familiarity with CRM software.",
        "Ability to work independently and as part of a team."
      ],
      preferredSkills: [
        "Experience in the technology or software industry.",
        "Knowledge of sales strategies and techniques.",
        "Familiarity with digital marketing."
      ],
      howToApply: "To apply, please fill out the application form provided below. We look forward to reviewing your application and potentially welcoming you to the Applotech team!" }
  ];

  const toggleJobDescription = (index) => {
    setExpandedJob(expandedJob === index ? null : index);
  };

  return (
    <div className="flex flex-col md:flex-row p-6 md:p-12 bg-gray-900">
      {/* Left Side */}
      <div className="w-full md:w-1/2 p-4 md:pr-8">
        <h4 className="text-lg text-white mb-4">Join Our Team</h4>
        <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold text-white mb-6 md:mb-8">
          Are you the one we’re <span className="text-orange-500">looking for?</span>
        </h2>
        <p className="text-base md:text-lg text-white mb-4">
        At Applotech, our hiring philosophy focuses on selecting individuals based on their attitude and passion for technology. We believe in providing comprehensive training to develop the necessary skills for success
           </p>
        <p className="text-base md:text-lg text-white mb-6 md:mb-14">
        Whether you’re currently in university, a recent graduate, or someone with diverse professional experience, we welcome all backgrounds and perspectives. We value the unique contributions each team member brings and are committed to fostering an inclusive and supportive work environment
        </p>
        <Link
          to=""
          className="inline-block mt-4 md:mt-8 px-6 py-2 rounded-3xl text-lg md:text-xl font-bold bg-orange-500 text-white hover:bg-white hover:text-orange-500 transition duration-300"
        >
          Apply Now
        </Link>
      </div>

      {/* Right Side */}
      <div className="w-full md:w-1/2 p-4">
        <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold text-orange-500 mb-4">
          Open <span className="text-white">Positions</span>
        </h2>
        <p className="text-base text-white mb-4">
          Explore the opportunities below and find the role that fits you.
        </p>

        {jobs.map((job, index) => (
          <div key={index} className="mb-6 border-b border-gray-700 pb-4">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => toggleJobDescription(index)}
            >
              <div className="flex items-center">
                {expandedJob === index ? (
                  <BiLockOpen className="text-orange-500 mr-2" />
                ) : (
                  <BiLock className="text-white mr-2" />
                )}
                <h3 className="text-lg md:text-xl font-semibold text-white">{job.title}</h3>
              </div>
              <BiChevronDown className="text-orange-500" />
            </div>
            {expandedJob === index && (
              <div className="mt-4 text-white">
                <h4 className="text-lg font-bold mb-2">Job Description</h4>
                <p className="mb-4">{job.description}</p>

                <h4 className="text-lg font-bold mb-2">Responsibilities</h4>
                <ul className="list-disc list-inside mb-4">
                  {job.responsibilities.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>

                <h4 className="text-lg font-bold mb-2">Requirements</h4>
                <ul className="list-disc list-inside mb-4">
                  {job.requirements.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>

                <h4 className="text-lg font-bold mb-2">Preferred Skills</h4>
                <ul className="list-disc list-inside mb-4">
                  {job.preferredSkills.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>

                <h4 className="text-lg font-bold mb-2">How to Apply</h4>
                <p>{job.howToApply}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CareerContent;
